import { ChonkyActions, FileHelper, FullFileBrowser, setChonkyDefaults } from 'chonky'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { deleteFile, getDirectory, getFile, uploadFile } from '../../actions/file_manager'
import { russianI18n } from './locale'

setChonkyDefaults({ iconComponent: ChonkyIconFA })

export const MyFileBrowser = ({
                                  path,
                                  instanceId,
                                  borderInvalid = false,
                                  height = 700,
                                  read_only = false,
                                  can_upload = true,
                                  can_download = true,
                                  can_delete = true,
                                  setIsEmpty = null,
                                  tags = null,
                                  getTags = null,
                                  year = null,
                                  handleUploadFile = (file) => null
                              }) => {
    const [fileMap, setFileMap] = useState(null)
    const [currentFolderId, setCurrentFolderId] = useState(instanceId)

    useEffect(() => {
        setCurrentFolderId(instanceId)
    }, [instanceId])

    useEffect(() => {
        if (!fileMap) {
            getDirectory(setFileMap, path, getTags).then((r) => {})
        }
        if (setIsEmpty) {
            setIsEmpty(fileMap?.[instanceId]?.childrenCount === 0)
        }
    }, [fileMap])

    const useFiles = (currentFolderId) => {
        return useMemo(() => {
            if (fileMap && Object.keys(fileMap).includes(currentFolderId)) {
                const currentFolder = fileMap[currentFolderId]
                let folderFiles = currentFolder?.childrenIds
                    ? currentFolder.childrenIds.map((fileId) => fileMap[fileId] ?? null) : []

                if (!year) return folderFiles

                let visibleFiles = []
                for (let fileId in folderFiles) {
                    let file = folderFiles[fileId]
                    if (!file?.tags?.year) {
                        visibleFiles.push(file)
                    } else if (file.tags.year === year.toString()) {
                        visibleFiles.push(file)
                    }
                }

                return visibleFiles
            }
            return []
        }, [fileMap, currentFolderId, year])
    }

    const useFolderChain = (currentFolderId) => {
        return useMemo(() => {
            if (fileMap && Object.keys(fileMap).includes(currentFolderId)) {
                const currentFolder = fileMap[currentFolderId]
                const folderChain = [currentFolder]

                let parentId = currentFolder?.parentId
                while (parentId) {
                    const parentFile = fileMap[parentId]
                    if (parentFile) {
                        folderChain.unshift(parentFile)
                        parentId = parentFile.parentId
                    } else {
                        parentId = null
                    }
                }

                return folderChain
            }
            return ''
        }, [fileMap, currentFolderId])
    }

    const useFileActionHandler = (setCurrentFolderId) => {
        if (!can_upload) can_delete = false
        return useCallback(
            (data) => {
                let currentPath
                if (instanceId === currentFolderId) {
                    currentPath = path
                } else {
                    currentPath = currentFolderId.substring(0, currentFolderId.length - 1) 
                }

                if (data.id === ChonkyActions.OpenFiles.id) {
                    const { targetFile, files } = data.payload
                    const fileToOpen = targetFile ?? files[0]
                    if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                        setCurrentFolderId(fileToOpen.id)
                        return
                    }
                }
                if (data.id === ChonkyActions.DownloadFiles.id) {
                    for (let file of data.state.selectedFiles) {
                        if (file.isDir) {
                            getFile(`${file.parentId}${file.name}`, '')
                        } else {
                            getFile(file.parentId, file.name)
                        }
                    }
                }
                if (data.id === ChonkyActions.UploadFiles.id) {
                    const handleUpload = (event) => {
                        event.preventDefault()
                        let formData = new FormData()

                        for (let i in event.target.files) {
                            formData.append('file', event.target.files[i])
                            formData.append('path', currentPath)
                            if (tags) {
                                for (let key in tags) {
                                    formData.append(`tag_${key}`, tags[key])
                                }
                            }
                            uploadFile('/api/file/', formData, setFileMap).then((r) => {
                                handleUploadFile(event.target.files[i])
                            })    
                        }
                        
                        getDirectory(setFileMap, currentPath, getTags).then((r) => {})
                    }
                    
                    const form = document.createElement('form')
                    form.enctype = 'multipart/form-data'

                    const input = document.createElement('input')
                    input.type = 'file'
                    input.multiple = true
                    form.onchange = handleUpload
                    form.onsubmit = (e) => {
                        e.preventDefault()
                    }

                    form.appendChild(input)
                    input.click()
                }
                if (data.id === ChonkyActions.DeleteFiles.id) {
                    for (let file of data.state.selectedFiles) {
                        deleteFile(currentPath, file.name, setFileMap)
                    }
                }
            },
            [fileMap, currentFolderId, setCurrentFolderId],
        )
    }

    const files = useFiles(currentFolderId)
    const folderChain = useFolderChain(currentFolderId)
    const handleFileAction = useFileActionHandler(setCurrentFolderId)

    const getFileActions = (read_only, can_delete, can_upload, can_download) => {
        if (read_only) return null
        if (can_delete) {
            return [
                ChonkyActions.UploadFiles,
                ChonkyActions.DownloadFiles,
                ChonkyActions.DeleteFiles,
            ]
        } else if (can_upload) {
            return [
                ChonkyActions.UploadFiles,
                ChonkyActions.DownloadFiles,
            ]
        } else if (can_download) {
            return [
                ChonkyActions.DownloadFiles,
            ]
        } else {
            return null
        }
    }

    return (
        <div style={{ height: height, border: borderInvalid ? '2px solid #dc3545' : '', borderRadius: '5px' }}>
            <FullFileBrowser
                instanceId={currentFolderId}
                files={files}
                folderChain={folderChain}
                onFileAction={handleFileAction}
                fileActions={getFileActions(read_only, can_delete, can_upload, can_download)}
                i18n={russianI18n}
            />
        </div>
    )

}
