import axiosInstance, { protectedAxios } from '../utils/axiosAPI'
import { getRandomString } from '../utils/utils'
import { toast } from 'react-toastify'

export const getDirectory = (setFunc, path, getTags = null) => {
    let params = { path: path }
    if (getTags) {
        for (let key in getTags) {
            params[`get_tag_${key}`] = getTags[key]
        }
    }
    
    return protectedAxios(axiosInstance.get, '/api/directory/', { params: params })
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data)
            }
        })
        .catch((err) => console.log(err))
}

export const getFile = (path, file, tags=null) => {
    let body = {
        params: {
            path: path,
            file: file,
            tags: tags,
        },
        headers:
            {
                'Content-Disposition': 'attachment;',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        responseType: 'blob',
    }

    protectedAxios(axiosInstance.get, '/api/file/', body)
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', file ? file : `${getRandomString(8)}.zip`)
            document.body.appendChild(link)
            link.click()
            link.remove()
        })
        .catch((err) => toast.warning('Не удалось найти файл!'))
}

export const uploadFile = (url = '/api/file/', formData, setFunc) => {
    let body = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }

    return protectedAxios(axiosInstance.post, url, formData, body)
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data)
            }
            return response
        })
        .catch((err) => {
            return err.response
        })
}


export const deleteFile = (path, file, setFunc) => {
    let body = {
        data: {
            path: path,
            file: file,
        },
    }

    protectedAxios(axiosInstance.delete, '/api/file/', body)
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data)
            }
        })
        .catch((err) => console.log(err))
}
