import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faSchool,
    faBriefcase,
    faSearch,
    faAddressCard,
    faMedal,
    faCalculator,
    faHome,
    faPlus,
    faUsers,
    faPaperPlane,
    faFolderOpen,
    faKey,
    faSignOutAlt,
    faSignInAlt,
    faUser,
    faAward,
    faDatabase,
    faDesktop,
    faFileContract,
    faThermometerHalf,
    faMapSigns,
    faProjectDiagram,
    faFileAlt,
    faCog,
    faChartLine,
    faRulerHorizontal,
    faUserTag,
    faCalendarAlt,
    faBuilding,
    faShieldAlt,
    faSitemap,
    faGraduationCap,
    faGlobe,
    faStreetView,
    faUserShield,
    faUserFriends,
    faUserGraduate,
    faBook,
    faLightbulb,
    faSortAmountUpAlt,
    faSortAmountDownAlt,
    faPencilAlt,
    faTrashAlt,
    faFilter,
    faSort,
    faWrench,
    faExternalLinkAlt,
    faExpand,
    faFileExcel,
    faFilePowerpoint,
    faTimes,
    faEye,
    faEyeSlash,
    faArrowsAltH,
    faLongArrowAltRight,
    faComment,
    faLifeRing,
    faSave,
    faRedoAlt,
    faCoins,
    faFileImport,
    faBan,
    faRedo,
    faCheck,
    faFileCsv,
    faFileCode,
    faMoneyBill,
    faComments,
    faDotCircle,
    faCircle,
    faArrowsAlt,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faSchool,
    faBriefcase,
    faAddressCard,
    faSearch,
    faCalculator,
    faPlus,
    faUsers,
    faMedal,
    faHome,
    faCog,
    faPaperPlane,
    faFolderOpen,
    faKey,
    faSignOutAlt,
    faSignInAlt,
    faUser,
    faDatabase,
    faFileContract,
    faDesktop,
    faAward,
    faThermometerHalf,
    faMapSigns,
    faProjectDiagram,
    faFileAlt,
    faFilePowerpoint,
    faChartLine,
    faRulerHorizontal,
    faUserTag,
    faCalendarAlt,
    faBuilding,
    faShieldAlt,
    faSitemap,
    faGraduationCap,
    faGlobe,
    faStreetView,
    faUserShield,
    faUserFriends,
    faUserGraduate,
    faBook,
    faLightbulb,
    faSortAmountUpAlt,
    faSortAmountDownAlt,
    faPencilAlt,
    faTrashAlt,
    faFilter,
    faSort,
    faWrench,
    faExternalLinkAlt,
    faExpand,
    faFileExcel,
    faTimes,
    faEye,
    faEyeSlash,
    faArrowsAltH,
    faLongArrowAltRight,
    faComment,
    faLifeRing,
    faSave,
    faRedoAlt,
    faCoins,
    faFileImport,
    faBan,
    faRedo,
    faCheck,
    faFileCsv,
    faFileCode,
    faMoneyBill,
    faComments,
    faDotCircle,
    faCircle,
    faArrowsAlt,
)
