import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import { Area, Check, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { DownloadFile } from '../../../components/file_manager/file_handlers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    CAN_APPROVE_ROLES,
    CAN_MANAGE_FILES_PDF,
    CAN_MANAGE_FILES_WORD,
    CAN_MANAGE_ROLES_COMMON,
    CAN_MANAGE_ROLES_RESULTS,
    MONTHS,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    SCIENCE_DIRECTIONS,
    STATUS_ACCEPT,
    STATUS_APPROVE,
    STATUS_CHECK,
    STATUS_PROCESS,
} from './constant'
import { btnMessage } from '../../../utils/utils'
import { Field, FieldArray, Formik, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../../components/form/formik'
import { toast } from 'react-toastify'
import { useAbac } from 'react-abac'

export const NiokrItem = ({
                              data,
                              setData,
                              user,
                              disabled,
                              messages,
                              handleChangeValue,
                              generateOptions,
                              ErrorsBlock,
                              MessageHistory,
                              ActionButtons,
                              DisableButton,
                              handleSubmit,
                              HeaderStatus,
                          }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()
    const { userHasPermissions } = useAbac()

    const [select_data, setSelectData] = useState({})

    const select_fields = ['person', 'project']
    const given_select_fields = ['science_dir']

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'type': '',
        'title': '',
        'partner': '',
        'grnti_program': '',
        'project_id': null,
        'save_form': false,
        'expected_result': '',
        'expected_result_year': [{
            year: new Date().getFullYear(),
            result: '',
            accepted: false,
            needAcceptResult: false,
            month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                month: 1,
                results: '',
                accepted: false,
                needAcceptResult: false,
            }, 0, 12),
        }],
        'supervisor': '',
        'presentation_link': '',
    })

    const formikHelper = useFormik({
        initialValues: {},
    })
    const [start, setStart] = useState(true)
    const [status, setStatus] = useState(1)
    const [move_index_plus, setMoveIndexPlus] = useState(1)
    const [show_modal, setShowModal] = useState(false)
    const [show_alert, setShowAlert] = useState(false)

    const [delete_year, setDeleteYear] = useState(null)
    const FILE_PATH = `root/storage/niokr/niokr_${params.id}`
    const [updateTags, setUpdateTags] = useState(null)

    const canUploadWord = user.roles
        .some((role) => CAN_MANAGE_FILES_WORD['upload'].includes(role))
    const canDownloadWord = user.roles
        .some((role) => CAN_MANAGE_FILES_WORD['download'].includes(role))
    const canUploadPdf = user.roles
        .some((role) => CAN_MANAGE_FILES_PDF['upload'].includes(role))
    const canDownloadPdf = user.roles
        .some((role) => CAN_MANAGE_FILES_PDF['download'].includes(role))
    const readOnlyPres = disabled && !(canUploadWord || canDownloadWord)

    const presTemplateFileTags = JSON.stringify({'document_type': 'template_presentation', 'DB': 'niokr'})

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveNiokr()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })
    const CAN_MANAGE_YEAR = userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
        || userHasPermissions(PERMISSIONS.ADMIN_PANEL)

    useEffect(() => {
        handleSubmit(formik, saveNiokr)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД НИОКР | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })

            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            if (!set_inputs?.expected_result_year) {
                set_inputs['expected_result_year'] = [{
                    year: new Date().getFullYear(),
                    result: '',
                    accepted: false,
                    needAcceptResult: false,
                    month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                        month: 1,
                        results: '',
                        accepted: false,
                        needAcceptResult: false,
                    }, 0, 12),
                }]
            }
            if (set_inputs?.expected_result_year) {
                let temp = {}
                set_inputs.expected_result_year.forEach((item) => {
                    temp[item?.year] = {
                        visible: false,
                    }
                })
                formikHelper.setValues({ ...temp })
            }

            setStatus(data?.status)
            formik.setValues({
                ...set_inputs, ...set_selected,
                current_status: data.status,
                without_message: true,
                strict_validation: true,
            })
            setStart(false)
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of ['project', 'person_select']) {
                    if (!Object.keys(select_data).includes(field)) {
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, {
                                type_data: 'user_projects',
                                'user_id': user.id,
                            }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        }
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (status === STATUS_PROCESS) {
            setMoveIndexPlus(-1)
        } else {
            setMoveIndexPlus(1)
        }
    }, [formik.values])

    useEffect(() => {
        if (formik.errors.expected_result_year) {
            setShowAlert(true)
        }
    }, [formik.errors])

    useEffect(() => {
       setUpdateTags({ 'project': formik.values['project_id'] })
    }, [formik.values['project_id']])
    
    useEffect(() => {
        if (delete_year) {
            let years = formik.values.expected_result_year
            let i
            for (i = 0; i < years.length; i++) {
                if (years[i].year === delete_year) {
                    break
                }
            }
            setDeleteYear(null)
            years.splice(i, 1)
        }
    }, [formik.values, delete_year])

    const saveNiokr = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values }
        } else if (STATUS_PROCESS === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, ...data?.acceptable }
        } else {
            payload = formik.values
        }

        if (updateTags) {
            const fileData = { path: FILE_PATH, tags: updateTags }
            putRequest('directory', fileData, false)    
        }
        
        updateRequest('niokr', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/niokr')
                setData(response.data)
            } else if (response.status === 400) {
                if (response.data?.message)
                    toast.error(response.data?.message)
                if (response.data?.fields)
                    formik.setErrors({ ...formik.errors, ...response.data?.fields })
            }
        })
        if (formik?.values?.message) {
            putRequest('niokr_messages', {
                content: formik?.values?.message,
                niokr_status: data.status,
                status: data.status,
                created_by: user.id,
                niokr: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }
        return Promise.resolve('is_saved')
    }

    const isDisabledRes = (isAccepted) => {
        if (disabled) return true
        let can_manage = user.roles.some((role) => CAN_MANAGE_ROLES_RESULTS[status].includes(role))
        let can_manage_accepted = (data?.status === STATUS_ACCEPT && (user.roles.some((role) => CAN_MANAGE_ROLES_RESULTS[status].includes(role))))
        if (can_manage_accepted) return false
        if (!can_manage) return true
        return isAccepted
    }

    const handleAddYear = (values) => {
        if (!CAN_MANAGE_YEAR) {
            setShowModal(false)
            return
        }

        if (formik.values.expected_result_year.find((item) => item?.year === Number(values?.year))) {
            toast.error('Введенный год уже присутствует в фактических значениях')
            return
        }
        formik.setFieldValue('expected_result_year', [...formik.values.expected_result_year, {
            year: values?.year,
            result: '',
            accepted: false,
            needAcceptResult: false,
            month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                month: 1,
                results: '',
                accepted: false,
                needAcceptResult: false,
            }, 0, 12),
        }])
        formikHelper.setValues({
            ...formikHelper.values,
            [values?.year]: {
                visible: false,
            },
        })
        setShowModal(false)
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>

                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД НИОКР`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_NIOKR}
                                       permission_manage={PERMISSIONS.MANAGE_NIOKR}
                                       move_index_plus={move_index_plus}
                                       can_back={false}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ minWidth: '250px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='niokr_message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          disabled={disabled}
                                          rows='5'
                                          value={formik?.values?.message}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <Modal size={'md'} show={show_alert} centered={true} onHide={() => setShowAlert(false)}>
                                <div className={'modal-content'}>
                                    <Modal.Header>
                                        <Modal.Title>
                                            ВНИМАНИЕ
                                        </Modal.Title>
                                        <button className={'close'} onClick={() => {
                                            setShowModal(false)
                                        }}><FontAwesomeIcon icon={'times'}/>
                                        </button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className={'text-center'}>
                                            <h5>Вы уверены, что ни одно поле не согласовано?</h5>
                                        </div>
                                        <div className={'text-center'}>
                                            Если вы хотите согласовать какие-то поля, то выберите, пожалуйста, эти поля,
                                            то есть поставьте галочку под полем
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div className={'button-actions'}>
                                            <Button variant={'success'}
                                                    onClick={() => {
                                                        saveNiokr().then()
                                                        setShowAlert(false)
                                                    }}>
                                                Ни одно поле не согласовано
                                            </Button>
                                            <Button variant={'warning'} onClick={() => {
                                                setShowAlert(false)
                                            }}>
                                                Отменить
                                            </Button>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                            <fieldset>
                                <Field component={FormikSelect}
                                       name={'science_dir'}
                                       label={'Научное направление'}
                                       id={'science_dir'}
                                       isSearchable={true}
                                       options={SCIENCE_DIRECTIONS}
                                       error={formik.errors['science_dir']}
                                       invalid={formik.errors['science_dir']}
                                       disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                       required={data ? REQUIRED_FIELDS[data.status].includes('science_dir') : false}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Тема НИОКР'} id={'title'}
                                               name={'title'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('title')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.title}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['title']}
                                               invalid={formik.errors['title']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'project_id'}
                                               label={'Проект'}
                                               id={'project_id'}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               error={formik.errors['project']}
                                               invalid={formik.errors['project']}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Индустриальный(ые) партнер(ы)'} id={'partner'}
                                               name={'partner'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('partner')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.partner}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['partner']}
                                               invalid={formik.errors['partner']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Руководитель / Отв. исп. НИОКР'} id={'supervisor'}
                                               name={'supervisor'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('supervisor')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.supervisor}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['supervisor']}
                                               invalid={formik.errors['supervisor']}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'ГРНТИ (Программа)'} id={'grnti_program'}
                                               name={'grnti_program'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('grnti_program')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.grnti_program}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['grnti_program']}
                                               invalid={formik.errors['grnti_program']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Ссылка на презентацию'} id={'presentation_link'}
                                               name={'presentation_link'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('presentation_link')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.presentation_link}
                                               disabled={disabled}
                                               error={formik.errors['presentation_link']}
                                               invalid={formik.errors['presentation_link']}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <hr/>
                            <fieldset>
                                <legend>План выполнения</legend>
                                <Area
                                    label={'Ожидаемый результат проекта'}
                                    id={'expected_result'}
                                    name={'expected_result'}
                                    handleChangeValue={formik.handleChange}
                                    value={formik.values.expected_result}
                                    step={'any'}
                                    disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                    required={REQUIRED_FIELDS?.[data?.status]?.includes('expected_result')}
                                    error={formik.errors['expected_result']}
                                    invalid={formik.errors['expected_result']}
                                />
                                <Modal size={'md'} show={show_modal} centered={true} onHide={() => setShowModal(false)}>
                                    <div className={'modal-content'}>
                                        <Modal.Header>
                                            <Modal.Title>
                                                Добавление ожидаемого результата за год
                                            </Modal.Title>
                                            <button className={'close'} onClick={() => {
                                                setShowModal(false)
                                            }}><FontAwesomeIcon icon={'times'}/>
                                            </button>
                                        </Modal.Header>
                                        <Formik
                                            initialValues={{ year: '' }}
                                            onSubmit={(values) => handleAddYear(values)}>
                                            {(props) => (
                                                <Form>
                                                    <Modal.Body>
                                                        <Input name={'year'} id={'year'}
                                                               label={'Год'}
                                                               max={2100}
                                                               min={1900}
                                                               type={'year'}
                                                               handleChangeValue={props.handleChange}/>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className={'button-actions'}>
                                                            <Button variant={'success'}
                                                                    onClick={() => props.submitForm()}>
                                                                <IconText icon={'plus'} text={'Создать'}/>
                                                            </Button>
                                                            <Button variant={'warning'} onClick={() => {
                                                                setShowModal(false)
                                                            }}>
                                                                Отменить
                                                            </Button>
                                                        </div>
                                                    </Modal.Footer>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Modal>
                            </fieldset>
                            <hr/>
                            <fieldset>
                                <legend>Результаты по годам</legend>
                                {CAN_MANAGE_YEAR
                                    && <Button className={'mb-4'}
                                               variant={'success'}
                                               onClick={() => setShowModal(true)}
                                    >
                                        Добавить год
                                    </Button>
                                }
                                <div className='alert alert-danger'
                                     style={{ display: formik.errors?.expected_result_year ? 'block' : 'none' }}>
                                    <p>Исправьте следующие ошибки:</p>
                                    <ul>
                                        <li>Необходимо заполнить и принять
                                            "Фактические результаты работы за месяц" за каждый
                                            месяц в каждом году.
                                        </li>
                                    </ul>
                                </div>
                                {formik.values?.expected_result_year ? formik.values?.expected_result_year?.map((item, index) => {
                                    return (<>
                                        {item?.year && <fieldset key={item?.year}>
                                            <legend>Результаты за {item?.year}</legend>
                                            <Button
                                                onClick={() =>
                                                    formikHelper.setFieldValue(
                                                        `${item?.year}.visible`,
                                                        !formikHelper.values?.[item?.year]?.visible)
                                                }>
                                                {formikHelper.values?.[item?.year]?.visible ? 'Скрыть' : 'Показать'}
                                            </Button>
                                            {CAN_MANAGE_YEAR
                                                && <Button variant={'danger'} className={'ml-2'}
                                                           onClick={() => setDeleteYear(item?.year)}>
                                                    <FontAwesomeIcon icon={'trash-alt'}/>
                                                </Button>
                                            }

                                            {formikHelper.values?.[item?.year]?.visible
                                                ? <FieldArray name={'expected_result_year'} render={(arrayHelpers) => {
                                                    return (
                                                        <div key={`inside ${item?.year}`}>
                                                            <br/>
                                                            <fieldset>
                                                                <legend>
                                                                    Ожидаемый результат за {item?.year}
                                                                </legend>
                                                                <Area
                                                                    label={''}
                                                                    id={`expected_result_year.${index}.result`}
                                                                    name={`expected_result_year.${index}.result`}
                                                                    handleChangeValue={formik.handleChange}
                                                                    value={item.result}
                                                                    step={'any'}
                                                                    disabled={isDisabledRes(item.accepted)}
                                                                    required={REQUIRED_FIELDS?.[data?.status]?.includes('expected_result_year')}
                                                                    error={formik.errors[`expected_result_year.${index}.result`]}
                                                                    invalid={formik.errors[`expected_result_year.${index}.result`] || (data?.status === STATUS_PROCESS && item.needAcceptResult && !item.accepted)}
                                                                />
                                                                {data && user.roles.some((role) => CAN_APPROVE_ROLES[data?.status].includes(role)) && data?.status === STATUS_PROCESS
                                                                    ? <Check
                                                                        label={'Принять результат за год'}
                                                                        id={`expected_result_year.${index}.accepted`}
                                                                        value={item.accepted}
                                                                        handleChangeValue={formik.handleChange}
                                                                    />
                                                                    : null}
                                                                {data && userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status === STATUS_APPROVE && !item.accepted
                                                                    ? <Check
                                                                        label={'Отправить на согласование информацию в поле выше'}
                                                                        id={`expected_result_year.${index}.needAcceptResult`}
                                                                        value={item.needAcceptResult}
                                                                        handleChangeValue={formik.handleChange}
                                                                    />
                                                                    : null}
                                                            </fieldset>
                                                            <fieldset>
                                                                <legend>Фактические результаты работы за месяц:</legend>
                                                                <FieldArray name={'month_results'}
                                                                            render={(arrayHelpers) => (
                                                                                <div
                                                                                    key={`month_results_${item?.year}`}>
                                                                                    {arrayHelpers.form.values?.expected_result_year[index].month_results?.map((month_res, month_index) => (
                                                                                        <fieldset
                                                                                            key={`${item?.year}, ${month_index}`}>
                                                                                            <Area
                                                                                                label={MONTHS[month_index + 1]}
                                                                                                id={`expected_result_year.${index}.month_results.${month_index}.results`}
                                                                                                name={`expected_result_year.${index}.month_results.${month_index}.results`}
                                                                                                handleChangeValue={formik.handleChange}
                                                                                                value={month_res.results}
                                                                                                step={'any'}
                                                                                                disabled={isDisabledRes(formik.values.expected_result_year[index].month_results[month_index]?.accepted)}
                                                                                                required={STATUS_CHECK === formik.values.status && !isDisabledRes(formik.values.expected_result_year[index].month_results[month_index]?.accepted)}
                                                                                                error={formik.errors['month_results']}
                                                                                                invalid={formik.errors['month_results'] || (data?.status === STATUS_PROCESS && month_res.needAcceptResult && !month_res.accepted)}
                                                                                            />
                                                                                            {data && user.roles.some((role) => CAN_APPROVE_ROLES[data?.status].includes(role)) && data?.status === STATUS_PROCESS
                                                                                                ? <Check
                                                                                                    label={'Принять результат за месяц'}
                                                                                                    id={`expected_result_year.${index}.month_results.${month_index}.accepted`}
                                                                                                    value={month_res.accepted}
                                                                                                    handleChangeValue={formik.handleChange}
                                                                                                />
                                                                                                : null}
                                                                                            {data && userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status === STATUS_APPROVE && !month_res.accepted
                                                                                                ? <Check
                                                                                                    label={'Отправить на согласование информацию в поле выше'}
                                                                                                    id={`expected_result_year.${index}.month_results.${month_index}.needAcceptResult`}
                                                                                                    value={month_res.needAcceptResult}
                                                                                                    handleChangeValue={formik.handleChange}
                                                                                                />
                                                                                                : null}
                                                                                        </fieldset>
                                                                                    ))}
                                                                                </div>
                                                                            )}/>
                                                            </fieldset>
                                                            <fieldset>
                                                                <legend>Отчет о научно исследовательской работе</legend>
                                                                <p>
                                                                    Оформление отчета по ГОСТ 7.32-2017 <br/>
                                                                    Система стандартов по информации, библиотечному и
                                                                    издательскому делу.
                                                                    Отчет о научно-исследовательской работе. Структура и
                                                                    правила оформления
                                                                </p>
                                                                <Input
                                                                    label={'Номер государственной регистрации'}
                                                                    id={`expected_result_year.${index}.gos_number`}
                                                                    name={`expected_result_year.${index}.gos_number`}
                                                                    handleChangeValue={formik.handleChange}
                                                                    value={item.gos_number}
                                                                    step={'any'}
                                                                    disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                                                    required={REQUIRED_FIELDS?.[data?.status]?.includes('gos_number')}
                                                                    error={formik.errors[`expected_result_year.${index}.result`]}
                                                                    invalid={formik.errors[`expected_result_year.${index}.gos_number`]}
                                                                />
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <h5>Отчет о научно исследовательской работе,
                                                                            word</h5>
                                                                        <small>Пожалуйста, загружайте файл с названием
                                                                            "[Фамилия руководителя]_НТО_[сокращенное
                                                                            название НИОКР]_[год]_word"
                                                                        </small>
                                                                        <MyFileBrowser
                                                                            height={280}
                                                                            path={`root/storage/niokr/niokr_${params.id}/word/${item?.year}`}
                                                                            read_only={disabled && !(canUploadWord || canDownloadWord)}
                                                                            tags={{
                                                                                'document_type': 'NTO_word',
                                                                                'year': item?.year,
                                                                                'project': formik.values.project_id,
                                                                                'niokr_item': params.id,
                                                                            }}
                                                                            can_upload={canUploadWord}
                                                                            can_download={canDownloadWord}
                                                                            instanceId={`${item?.year}`}
                                                                        />
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <h5>Отчет о научно исследовательской работе,
                                                                            pdf</h5>
                                                                        <small>Пожалуйста, загружайте файл с названием
                                                                            "[Фамилия руководителя]_НТО_[сокращенное
                                                                            название НИОКР]_[год]_pdf"
                                                                        </small>
                                                                        <MyFileBrowser
                                                                            height={280}
                                                                            path={`root/storage/niokr/niokr_${params.id}/pdf/${item?.year}`}
                                                                            read_only={disabled && !(canUploadWord || canDownloadWord)}
                                                                            tags={{
                                                                                'document_type': 'NTO_pdf',
                                                                                'year': item?.year,
                                                                                'project': formik.values.project_id,
                                                                                'niokr_item': params.id,
                                                                            }}
                                                                            can_upload={canUploadPdf}
                                                                            can_download={canDownloadPdf}
                                                                            instanceId={`${item?.year}`}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <br/>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <h5>Презентация</h5>
                                                                    </Col>
                                                                    <Col md={10}>
                                                                        <small>Пожалуйста, загружайте файл с названием
                                                                            "[Фамилия
                                                                            руководителя]_Презентация_[сокращенное
                                                                            название НИОКР]_[год]"
                                                                        </small>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <DownloadFile folder={'root/templates/'}
                                                                                      file={'pish_NIOKR_presentation_template.pptx'}
                                                                                      title={'niokr'}
                                                                                      label={'Шаблон презентации:  '}
                                                                                      withIcon={false}
                                                                                      tags={presTemplateFileTags}
                                                                        />
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <MyFileBrowser
                                                                            path={`root/storage/niokr/niokr_${params.id}/presentation/${item?.year}`}
                                                                            instanceId={`${item?.year}`}
                                                                            read_only={readOnlyPres}
                                                                            tags={{
                                                                                'document_type': 'presentation',
                                                                                'year': item?.year,
                                                                                'project': formik.values.project_id,
                                                                                'niokr_item': params.id,
                                                                            }}
                                                                            borderInvalid={data?.status === STATUS_PROCESS && item.needPresentationAccept && !item.presentationAccepted}
                                                                            can_upload={canUploadWord}
                                                                            can_download={canDownloadWord}
                                                                        />
                                                                        {data && user.roles.some((role) => CAN_APPROVE_ROLES[data?.status].includes(role)) && data?.status === STATUS_PROCESS
                                                                            ? <Check
                                                                                label={'Принять результат за год'}
                                                                                id={`expected_result_year.${index}.presentationAccepted`}
                                                                                value={item.presentationAccepted}
                                                                                handleChangeValue={formik.handleChange}
                                                                            />
                                                                            : null}
                                                                        {data && userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status === STATUS_APPROVE && !item?.presentationAccepted
                                                                            ? <Check
                                                                                label={'Отправить на согласование информацию в поле выше'}
                                                                                id={`expected_result_year.${index}.needPresentationAccept`}
                                                                                value={item.needPresentationAccept}
                                                                                handleChangeValue={formik.handleChange}
                                                                            />
                                                                            : null}
                                                                    </Col>
                                                                </Row>
                                                            </fieldset>
                                                        </div>
                                                    )
                                                }}/>
                                                : null}
                                            <br/>
                                            <br/>
                                        </fieldset>}
                                    </>)
                                }) : null}
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
